import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Ласкаво просимо до гончарної студії ClayCraft
			</title>
			<meta name={"description"} content={"Там, де креативність набуває форми"} />
			<meta property={"og:title"} content={"Ласкаво просимо до гончарної студії ClayCraft"} />
			<meta property={"og:description"} content={"Там, де креативність набуває форми"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Text margin="0px 0px 0px 0px" color="--dark" font="--headline1">
				Наші пропозиції
			</Text>
			<Text
				text-align="left"
				color="--darkL2"
				font="--base"
				margin="16px 600px 16px 0px"
				lg-margin="16px 0 16px 0px"
			>
				У ClayCraft Pottery Studio ми пишаємося тим, що пропонуємо широкий асортимент пропозицій, щоб задовольнити всі ваші гончарні потреби. Незалежно від того, чи ви новачок, який прагне почати, чи досвідчений митець, який прагне відточити свої навички, наші комплексні пропозиції створені, щоб надати вам найкращий досвід гончарства. Пориньте в наші пропозиції та подивіться, як ми можемо допомогти вам створити щось справді особливе.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				align-items="center"
				align-self="center"
				justify-items="center"
				align-content="center"
				justify-content="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Text color="--dark" font="--headline3" lg-margin="0 0px 0px 0px" margin="0 0px 2rem 0px">
						Уроки гончарства для всіх рівнів кваліфікації
					</Text>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14:26:32.796Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							srcSet="https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14%3A26%3A32.796Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14%3A26%3A32.796Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14%3A26%3A32.796Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14%3A26%3A32.796Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14%3A26%3A32.796Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14%3A26%3A32.796Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-3%20%281%29.jpg?v=2024-06-24T14%3A26%3A32.796Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Наші заняття з гончарства є серцем ClayCraft. Ми пропонуємо різноманітні заняття, адаптовані до різних рівнів кваліфікації, гарантуючи, що кожен, від початківця до досвідченого гончара, зможе знайти заняття, яке відповідає його потребам.{"\n"}
							<br />
							Класи для початківців: ці класи ідеально підходять для тих, хто тільки починає гончарство, охоплюють основи рукотворного будівництва та метання колеса.
 Знайомство з керамікою: ознайомтеся з основами роботи з глиною, включно з основними техніками та термінологією.{"\n"}
							<br />
							{" "}Основи ручного виготовлення: досліджуйте мистецтво створення кераміки вручну, використовуючи такі техніки, як затискання, згортання та створення плит.
							<br />
							{"\n "}Основи метання колеса: отримайте практичний досвід роботи з гончарним кругом, навчіться центрувати глину та створювати основні форми.
Класи середнього рівня: призначені для тих, хто має певний досвід, ці класи допоможуть вам удосконалити свої навички та дослідити нові техніки.
 Розширене кидання колеса: виведіть свої навички кидання колеса на наступний рівень завдяки складнішим формам і дизайнам.{"\n"}
							<br />
							{" "}Техніки декорування поверхні: навчіться різноманітним методам декорування гончарних виробів, включаючи глазурування, різьблення та малювання.{"\n"}
							<br />
							{" "}Функціональна кераміка: зосередьтеся на створенні практичних виробів, як-от кухлів, мисок і тарілок із професійною обробкою.
							<br />
							{"\n"}Просунуті класи: для досвідчених гончарів ці заняття пропонують поглиблене вивчення передових технік і художнього вираження.
 Опанування колеса: вдосконалюйте складні форми та розширюйте межі метання колеса.
 Скульптурна кераміка: поєднуйте кераміку та скульптуру, щоб створити унікальні художні твори.
 Хімія глазурі: зануртеся в науку глазурі та навчіться створювати власні оздоблення.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="--headline3" lg-margin="0 0px 0px 0px" margin="0 0px 2rem 0px">
							Спеціалізовані семінари та заходи
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Окрім наших звичайних занять, ми пропонуємо низку спеціалізованих семінарів і заходів, покликаних надихнути та кинути вам виклик.{"\n"}
							<br />
							Сезонні майстер-класи: приєднуйтесь до нас на тематичні майстер-класи протягом року, наприклад, виготовлення святкових прикрас або літня кераміка для саду.{"\n"}
							<br />
							{" "}Святкові твори: створюйте унікальні прикраси та подарунки для святкового сезону.{"\n"}
							<br />
							{" "}Садова кераміка: створюйте красиві та функціональні вироби для свого саду, як-от кашпо та ванни для птахів.{"\n"}
							<br />
							Серія запрошених художників: вчіться у запрошених художників, які привносять у нашу студію свої унікальні стилі та техніки.{"\n"}
							<br />
							{" "}Майстер-класи: інтенсивні заняття з відомими гончарями, зосереджені на передових техніках і мистецькому розвитку.{"\n"}
							<br />
							{" "}Artist Talks: дізнайтеся про творчий процес і натхнення, що лежить в основі роботи наших запрошених художників.{"\n"}
							<br />
							Сімейні та групові заходи: насолоджуйтеся керамікою разом із родиною та друзями на наших веселих та інтерактивних групових заняттях.{"\n"}
							<br />
							{" "}Сімейний день гончарства: веселий день гончарства для будь-якого віку, де сім’ї можуть творити разом.{"\n"}
							<br />
							{" "}Майстер-класи з побудови команди: зміцнюйте динаміку команди за допомогою спільних гончарних проектів, розроблених для корпоративних груп.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14:26:32.786Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							srcSet="https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14%3A26%3A32.786Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14%3A26%3A32.786Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14%3A26%3A32.786Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14%3A26%3A32.786Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14%3A26%3A32.786Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14%3A26%3A32.786Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-2.jpg?v=2024-06-24T14%3A26%3A32.786Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14:26:32.764Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							max-height="100%"
							srcSet="https://smartuploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14%3A26%3A32.764Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14%3A26%3A32.764Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14%3A26%3A32.764Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14%3A26%3A32.764Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14%3A26%3A32.764Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14%3A26%3A32.764Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/1-2.jpg?v=2024-06-24T14%3A26%3A32.764Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					align-self="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="--headline3" lg-margin="0 0px 0px 0px" margin="0 0px 2rem 0px">
							Приватні уроки та членство в студії
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Для тих, хто шукає більш персоналізований або гнучкий досвід гончарства, ми пропонуємо приватні уроки та членство в студії.{"\n"}
							<br />
							Приватні уроки: індивідуальні інструкції, адаптовані до ваших потреб і цілей.
 Індивідуальний навчальний план: працюйте над техніками та проектами, які вас найбільше цікавлять, у власному темпі.{"\n"}
							<br />
							{" "}Гнучкий розклад: організовуйте уроки у зручний для вас час.{"\n"}
							<br />
							Членство в студії: насолоджуйтеся необмеженим доступом до наших засобів і матеріалів.
							<br />
							{"\n "}Доступ до студії 24/7: створюйте в будь-який час із повним доступом до нашої студії та обладнання.
 Поставки зі знижкою: отримуйте спеціальні знижки на глину, глазур та інструменти.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					align-self="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Зв’яжіться з нами, щоб дізнатися більше
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Це лише короткий огляд того, що ми пропонуємо в ClayCraft Pottery Studio. Щоб дізнатися про весь спектр наших послуг і дізнатися, як ми можемо допомогти вам у вашій гончарній подорожі, зв’яжіться з нами сьогодні. Наша дружня команда готова відповісти на будь-які ваші запитання та допомогти вам знайти ідеальний клас або послугу, яка відповідатиме вашим потребам. Завітайте до нас, щоб побачити нашу студію та дізнатися більше про те, що ми пропонуємо.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14:26:32.775Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							srcSet="https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14%3A26%3A32.775Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14%3A26%3A32.775Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14%3A26%3A32.775Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14%3A26%3A32.775Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14%3A26%3A32.775Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14%3A26%3A32.775Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e863f24780021f82593/images/2-1.jpg?v=2024-06-24T14%3A26%3A32.775Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});